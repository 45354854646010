.footer {
  margin: 25px 25px 15px 30px;
}
.foot {
  display: flex;
  gap: 5%;
  padding: 40px 0 50px;
  /* border-top: 1px solid #adadad; */
  border-bottom: 1px solid #adadad;
}
.foot-left {
  display: flex;
  width: 55%;
  justify-content: space-between;
}
.foot-left-first {
  width: 62%;
}
.foot-left-first p {
  margin: 30px 0;
  font-family: var(--font-family);
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
}
.foot-subscribe p {
  margin-bottom: 5px;
}
.foot-right {
  display: flex;
  width: 40%;
  justify-content: space-between;
}

.foot-quick {
  width: 20%;
}
.foot-contact {
  width: 50%;
}
.foot-follow {
  width: 40%;
}
.foot-quick h3,
.foot-contact h3,
.foot-follow h3 {
  font-family: var(--font-family);
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  text-align: left;
  margin-bottom: 20px;
}
.foot-quick-links {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.foot-quick-links a,
.foot-contact-details p {
  text-decoration: none;
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: black;
  padding-left: 2px;
}

.foot-subscribe-btns input {
  width: 50%;
  border: 1px solid #adadad;
  border-radius: 5px;
  outline: none;
  padding: 10px;
  color: black;
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
}
.foot-subscribe-btns input::placeholder {
  color: #ADADAD;
}
.foot-subscribe-btns button {
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  border: none;
  padding: 9px 30px;
  margin-left: 25px;
  border-radius: 8px;
  background: linear-gradient(90deg, #2c67f2 -105.53%, #62cff4 100%);
  color: white;
}

.foot-contact-details {
  display: flex;
  align-items: flex-start;
  gap: 15px;
}

.foot-social-media {
  display: flex;
  gap: 10px;
}

.foot-copyright {
  padding-top: 15px;
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
}

@media all and (max-width: 1024px) {
  .foot {
    flex-direction: column;
    gap: 50px;
  }
  .foot-left,
  .foot-right {
    width: 100%;
  }
  .foot-contact {
    width: 50%;
  }
  .foot-follow {
    width: 20%;
  }
}
@media all and (max-width: 768px) {
  .foot-quick,.foot-follow{
    width: 24%;
  }
}
@media all and (max-width: 672px){
  .foot-left,.foot-right{
    flex-direction: column;
    align-items: center;
    row-gap: 40px;
  }
  .foot-contact,.foot-follow,.foot-left-first,.foot-quick{
    width: 80%;
  }
}
@media all and (max-width: 500px){
  .foot-contact,.foot-follow,.foot-left-first,.foot-quick{
    width: 100%;
  }
  .foot-subscribe-btns input{
    width: 45%;
  }
}

