
.contact{
    position: relative;
}


.contact-section{
    display: flex;
    justify-content: space-between;
    margin: 150px 30px 0 50px;
    gap: 10%;
}
.contact-left{
    width: 55%;
}
.contact-right{
    width: 36%;
}
.contact-details{
    background: linear-gradient(180deg, #2C67F2 0%, #62CFF4 100%);
    padding: 10px 20px;
     border-radius: 20px;
}
.contact-card p,.contact-card h3{
    color: white;
}
.contact-card-head{
    margin-bottom: 15px;
}
.contact-card-head h3{
    font-family: var(--font-family);
font-size: 48px;
font-weight: 600;
line-height: 72px;
text-align: left;
margin: 0;
}
.contact-card-head p,.contact-visit .font-para{
    font-weight: 500;
}
.contact-address{
    display: flex;
    gap: 15px;
    align-items: center;
}

.contact-address p{
font-family: var(--font-family);
font-size: 16px;
font-weight: 400;
line-height: 24px;
text-align: left;
margin: 0;
}

.contact-visit{
    margin-bottom: 15px;
}
.contact-visit .font-para{
    margin-bottom: 5px;
}


.contact-right form{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 98%;
    margin-right: 15px;
}

.contact-form-input{
    display: flex;
    flex-direction: column;
    gap: 15px;
}
.contact-form-input input{
    outline: none;
    border: 1px solid #ADADAD;
    border-radius: 8px;
    padding: 6px;
}
.contact-form-input textarea{
   resize: none;
   outline: none;
   border: 1px solid #ADADAD;
   border-radius: 8px;
   padding: 8px;
   height: 120px;
}
.contact-form-input input::placeholder,.contact-form-input textarea::placeholder{
    color: #ADADAD;
    font-family: var(--font-family);
font-size: 16px;
font-weight: 400;
line-height: 24px;
text-align: left;
}

.contact-right form button{
    outline: none;
    border: none;
    background: linear-gradient(90deg, #2C67F2 0%, #62CFF4 100%);
    padding: 7px;
    border-radius: 8px;
    text-align: center;
    color: white;
}

.contact-map{
    margin-top: 15px;
}

.contact-map iframe{
    border: 0px;
    border-radius: 20px;
    height: 280px;
    border: 1px solid #ADADAD;
    width: 100%;
}
.errors{
    color: red;
}


@media all and (max-width: 768px) {
    .contact-section{
    flex-direction: column;
    gap: 50px;
    align-items: center;
    } 
    .contact-section-head{
        width: 100%;
    }
    .contact-left{
      width: 80%;
    }
    .contact-right{
      width: 50%;
    }
    .contact-right form{
        gap: 20px;
    }

}

@media all and (max-width: 630px) {
   
    .contact-left{
      width: 90%;
    }
    .contact-right{
      width: 70%;
    }


}
@media all and (max-width: 450px) {
   
    .contact-left{
      width: 100%;
    }
    .contact-right{
      width: 80%;
    }


}