.about{
    position: relative;
}

.about-details{
    margin-bottom: 100px ;
}

.about-share{
    position: relative;
}
.about-share .share-icon{
    transform: translateX(300%);
}
.about-share .home-head-share{
    right: 200px;
    transform: translateX(75%);
}
.about-section{
    margin-top: 150px;
    align-items: flex-start;
}
.product-title{
    font-weight: 600 !important;
    margin-bottom: 10px !important;
}