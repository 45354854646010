.carousal-slider .mySwiper {
  width: 100%; /* Adjust width as needed */
  height: 500px;
  margin: 0 !important;
   /* Adjust height as needed */
}

.carousal-slider .mySwiper img {
  width: 60%;
  height: 85%;
  object-fit: cover;
  border-radius: 30px; /* Ensures the images cover the slides properly */
}
.carousal-slider .swiper-slide{
  width: 100% !important;

}
.carousal-slider .swiper-slide-next ,.carousal-slider.swiper-slide-prev{
  transform: translate3d(-20%, -20%, -1200px) rotateX(0deg) rotateY(0deg) scale(1) !important;
  filter: blur(7px);
  background: transparent !important;
  height: 600px !important;
}
.carousal-slider .swiper-slide-next img{
  width: 100%;
  height: 100%;
}
.carousal-slider .swiper-3d .swiper-slide-shadow-left,.carousal-slider .swiper-3d .swiper-slide-shadow-right{
  width: 70%;
  background: transparent !important;
}

.carousal-slider .swiper-button-next,
.carousal-slider .swiper-button-prev {
  background: transparent;
  border: 1px solid   #62CFF4 !important; /* Initial transparent border */
  border-radius: 50% ;
  width: 40px !important;
  height: 40px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  position: absolute !important;
  z-index: 10 !important;
  top: 95% !important;
  color: black !important;

  
}
.carousal-slider .swiper-button-next {
  left: 20% !important;
}
.carousal-slider .swiper-button-next:hover,
.carousal-slider .swiper-button-prev:hover{
  background: linear-gradient(20deg, #2C67F2 0%, #62CFF4 100%);
  transition: background .5s ease-in-out;
  color: white !important;
}

.carousal-slider .swiper-button-next::after,.carousal-slider .swiper-button-prev::after{
  font-size: 20px !important;
}


@media all and (max-width: 768px){
.carousal-slider .mySwiper{
  width: 100%;
  height: 600px;
}
.carousal-slider .mySwiper img{
  width: 50%;

}
.carousal-slider .swiper-slide-next img{
  width: 65%;
}
.carousal-slider .swiper-slide-next{
  transform: translate3d(-28%, -12%, -500px)rotateX(0deg) rotateY(0deg) scale(1) !important;
}
}

@media all and (max-width: 540px){
  .carousal-slider .mySwiper img{
    width: 63%;
    height: 70%;
  
  }
  .carousal-slider .swiper-slide-next img{
    width: 70%;
    height: 70%;
  }
    .carousal-slider .swiper-slide-next {
        transform: translate3d(-28%, -2%, -200px) rotateX(0deg) rotateY(0deg) scale(1) !important;
    }
}

/* @media all and (max-width: 500px){
  .carousal-slider .mySwiper img{
    width: 63%;
    height: 70%;
  
  }
  .carousal-slider .swiper-slide-next img{
    width: 60%;
  }
} */



@media all and (min-width:1240px) {
  .carousal-slider .mySwiper{
    height: 600px;
  }
  .carousal-slider .swiper-slide-next{
    transform: translate3d(-20%, -10%, -600px) rotateX(0deg) rotateY(0deg) scale(1) !important;
  }
  
}