@import "../css/pages/navbar.css";
@import "../css/pages/home.css";
@import "../css/pages/footer.css";
@import "../css/pages/about.css";
@import "../css/pages/carousel.css";
@import "../css/pages/toollist.css";
@import "../css/pages/contact.css";
:root {
  --font-family: "Poppins", sans-serif;
}

.font-head {
  font-family: var(--font-family);
  font-size: 48px;
  font-weight: 500;
  line-height: 60px;
  text-align: left;
  color: #000000;
  margin-bottom: 15px;
}
.font-para {
  font-family: var(--font-family);
  font-size: 17px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  color: black;
  margin: 0;
}

.app {
  margin: 0 auto;
  max-width: 1632px;
  padding: 0;
  box-sizing: border-box;
}
hr{
  margin-top: 100px !important;

  border-color: #ADADAD !important;
}