.home {
  margin: 0;
  position: relative;
}
.home-head {
  display: flex;
  justify-content: space-between;
  gap: 5%;
  align-items: center;
  margin: 50px 0px 20px 30px;
}
.home-head-left {
  width: 50%;
  margin-top: -20px;
}
.home-head-right {
  width: 39%;
  position: relative;
  overflow: hidden;
}

.page-head {
  font-family: var(--font-family);
  font-size: 54px;
  font-weight: 600;
  line-height: 72px;
  text-align: left;
}

.readmore-btn {
  padding: 10px 40px;
  background: transparent;
  border: 1px solid;
  border-image: linear-gradient(90deg, #2c67f2 0%, #62cff4 100%);
  border-image-slice: 1;
  position: relative;
  z-index: 1;
  overflow: hidden;
  transition: background-position 0.5s ease;
}

.readmore-btn::before {
  content: "";
  position: absolute;
  top: 100%;
  left: 30%;
  width: 30%;
  height: 200%;
  background: linear-gradient(20deg, #2c67f2 0%, #62cff4 100%);
  z-index: -1;
  border-radius: 70%; /* Initial border-radius */
  transition: top 0.3s cubic-bezier(0.075, 0.82, 0.165, 1),
    border-radius 0.3s ease-in-out;
}

.readmore-btn:hover::before {
  top: 0;
  left: 0;
  width: 100%;
  border-radius: 0; /* Change border-radius to 0 on hover */
}
.readmore-btn:hover {
  color: white;
}

/* home-head */
.home-head-left h3 {
  font-family: var(--font-family);
  font-size: 54px;
  font-weight: 600;
  line-height: 72px;
  text-align: left;
}
.home-head-left p {
  font-family: var(--font-family);
  font-size: 17px;
  font-weight: 500;
  line-height: 30px;
  text-align: left;
}
.home-head-left button {
  font-family: var(--font-family);
  font-size: 17px;
  font-weight: 500;
  line-height: 30px;
  text-align: left;
  background-color: #000000;
  border-radius: 31px;
  color: white;
  padding: 5px 10px;
  border: none;
  outline: none;
  margin-top: 20px;
}

.home-head-left button img {
  background-color: white;
  border-radius: 50%;
  padding: 8px;
  width: 30px;
  height: 30px;
}

.home-head-share {
  display: flex;
  position: absolute;
  bottom: 6px;
  right: 30px;
}
.head-share-icons {
  display: flex;
  gap: 10px;
}
.share-icon {
  cursor: pointer;
  z-index: 1;
  padding: 10px;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  border: 1px solid #62cff4;
  display: flex;
  align-items: center;
  justify-content: center;
  /* transition: transform 0.3s ease; */
  /* transform: translateX(300%); */
}
.head-share-icons {
  border: 1px solid #62cff4;
  border-left: none;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  display: none;
  align-items: center;
  margin-left: -20px;
  padding-left: 20px;
}

.head-share-icons img {
  width: 35px;
  height: 35px;
}
.share-icon-active {
  transform: translateX(0) !important;
  z-index: 1;
}
.head-share-icons-active {
  display: flex;
}
.head-share-icons a {
  cursor: pointer;
  z-index: 1;
}

/* section */

.section {
  margin: 70px 25px 70px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 80px;
}
.section-reverse {
  margin: 70px 25px 0 30px !important;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  gap: 50px;
}
.section-btn {
  margin-top: 30px;
}
.section-right img,
.section-right video {
  width: 36vw;
  height: 42vw;
}
.section-right video {
  border-radius: 44px;
}
.section-quality-right img {
  width: 36vw;
  height: 29vw;
}

/* card-section */

.rate-section {
  /* margin-right: -11px; */
  /* margin-left: -13px; */
  background-image: url(../../images/home/tool-background.svg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 350px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 100px;
}

.rate_card {
  width: 23%;
  height: 197px;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  background-color: #000000;
}

.rate_card:hover {
  background: linear-gradient(90deg, #62cff4 0%, #2c67f2 100%);
}

.rate_card img {
  width: 70px;
  height: 70px;
}

.rate_card:hover img {
  filter: brightness(0) invert(1);
}

.card_body {
  text-align: center;
}
.card_body h2 {
  font-family: var(--font-family);
  color: #ffffff;
  font-size: 32px;
  font-weight: 500;
  margin-bottom: 6px;
}

.card_body p {
  font-family: var(--font-family);
  color: #ffffff;
  font-size: 17px;
  font-weight: 500;
  margin: 0;
}

/* vision-mission-goal */
.vision-goal {
  display: flex;
  gap: 20px;
  margin: 0 25px;
  /* height: 301px; */
}

.mission-head {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: 20px;
}
.mission-head h3 {
  margin: 0;
  font-family: var(--font-family);
  font-size: 32px;
  font-weight: 500;
  line-height: 48px;
  text-align: left;
}
.mission-head img {
  width: 74px;
  height: 70px;
}

.home-mission {
  border: 1px solid #62cff4;
  border-radius: 24px;
  padding: 15px;
  width: 33%;
  background-position: bottom;
  background-size: 100% 200%;
  transition: background-position 0.5s ease-in-out, color 0.5s ease-in-out,
    background-color 1s ease-in-out;
}

.home-mission:hover {
  background: linear-gradient(180deg, #62cff4 0%, #2c67f2 100%);
  color: white;
}

.home-mission:hover .mission-head h3,
.home-mission:hover p {
  color: white;
  transition: color 0.5s ease-in-out;
}

.home-mission:hover img {
  filter: brightness(0) invert(1);
  transition: filter 0.5s ease-in-out;
}

/* ourClients */
.clients {
  margin: 70px 0 100px 0;
}
.client-head {
  width: 50%;
  margin-left: 25px;
}
.seclogo-wrapper {
  overflow: hidden;
  white-space: nowrap;
 
}

.seclogo {
  margin-top: 50px;
  display: flex;
  scroll-behavior: smooth;
  overflow: hidden;
  white-space: nowrap;
  width: fit-content;
  animation: loop 5s linear infinite;
}

.slider1,
.slider5 {
  margin: 0 !important;
}
.seclogo img {
  margin: 0 40px;
  height: 100px;
  width: 250px;
  object-fit: contain;
}

@keyframes loop {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-50%);
  }
}

/* meadia-queries */

@media all and (max-width: 1024px) {
  
  .home-head-right {
    width: 41.8%;
  }
   .section-right img{
    width: 38vw;
    height: 46vw;
   }
  .home-head-left h3 {
    font-size: 43px;
  }
  .home-head-left p {
    font-size: 17px;
  }
  .section-right video {
    width: 50vw;
    height: 58vw;
  }
 
}
@media all and (max-width: 990px) and (min-width: 769px) {
  .home-head-right{
    width: 50%;
  }
  .section-right img,
  .section-right video,.section-quality-right img {
    width: 42.5vw;
    height: 56vw;

   
  }
  .section-right video {
    height: 50vw;
  }
}

@media all and (max-width: 768px) {
  .section-right img,
  .section-right video,.section-quality-right img {
    width: 370px;
    height: 430px;
  }

  .font-head{
    font-size: 40px !important;
  }
  .font-para{
    font-size: 17px !important;
  }
  .home-head {
    flex-direction: column;
    align-items: flex-start;
    row-gap: 60px;
  }
  .home-head-left {
    width: 95%;
  }
  .home-head-right {
    width: 100%;
  }
  .section,
  .section-reverse {
    flex-direction: column;
    align-items: center !important;
  }
  .vision-goal{
    flex-direction: column;
    align-items: center;
  }
  .vision-goal .home-mission{
    width: 80%;
  }
  .client-head{
    width: 95%;
  }
  .rate-section{
    height: 450px;
  }
  .rate_card{
    width: 40%;
  }
}


@media all and (max-width: 530px) {
  .rate-section{
    height: 900px;
  }
  .rate_card{
    width: 70%;
  }
  .section-right img,
  .section-right video,.section-quality-right img {
    width: 320px;
    height: 370px;
  }
  .section-right video {
    height: 390px;
  }

}
@media all and (max-width: 430px) {
  .home-head-share{
    bottom: 10px;
    right: 0px
    }
}

@media all and (min-width: 1450px) {
  .section-right img,
  .section-right video,.section-quality-right img {
    width: 610px;
    height: 720px;
  }
}