.product-section {
    margin: 30px 25px;
    font-family: 'Poppins', sans-serif;
}

/* tool-cardS  */
.tool-list {
    display: flex;
    justify-content: space-between;
    align-items: center;   
    gap: 10px;
    margin: 45px 20px;
}

 .tool-list-prev,.tool-list-next{
    height: 50px;
    width: 50px;
    border-radius: 50%;
    background: transparent;
    border: 1px solid skyblue;
    padding: 15px;
    line-height: 0;
}
.tool-list-prev:hover,.tool-list-next:hover{
    background: linear-gradient(90deg, #62cff4 0%, #2c67f2 100%);

}
.tool-list-prev:hover img,.tool-list-next:hover img{
    filter: brightness(0) invert(1);
}



.tool-list-next{
     transform: rotate(180deg);
}

.tool-card {
    width: 120px;
    padding: 20px;
    text-align: center;
    /* transition: all 0.3s ease; */
    border: 1px solid #ddd;
    cursor: pointer;
    height: 650px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

}
.tool-list .swiper{
    margin: 0;
    margin-left: 30px;
    width: 100%;
}

.tool-list .swiper-slide{
    transform: translateX(0) !important;
    width: 120px !important;
    margin-right: 0 !important;
}

.tool-list .swipe-expand{
    width: 400px !important;
}

.tool-list .swiper-wrapper{
    gap: 40px;
}


.tool-card img {
    max-width: 100%;
    height: 220px;
    /* transition: transform 0.3s ease; */

}

.tool-card h2 {
    transform: rotate(-90deg);
    font-family: var(--font-family);
    font-size: 30px;
    font-weight: 500;
    line-height: 54px;
    text-align: left;
    white-space: nowrap;
    margin-bottom: 20px;
}

.tool-card-content {
    display: none;

}
.tool-card-content p{
    font-weight: 500 !important;
     font-size: 17px;
}
.tool-card.tool-expanded {
    width: 100% !important;
    height: auto;
    background: linear-gradient(180deg, rgba(98, 207, 244, 0.15) 0%, rgba(44, 103, 242, 0.1) 100%);
    position: relative;
    height: 650px;
    flex-direction: column-reverse;
}

.tool-card.tool-expanded img {
    transform: scale(1.5);
    height: 150px;
    background: transparent;
}

.tool-card.tool-expanded .tool-card-content {
   display: block;
}



.tool-card.tool-expanded h2 {
    margin: 20px;
    transform: rotate(0);
    margin: 0;
}



.tool-card.tool-expanded .tool-card-content button {
    margin-top: 20px;
    margin-left: 50%;
    width: 45%;
    background-color: #FFFFFF !important;
    padding: 7px 10px;
}



@media all and (max-width: 1025px) {
    .tool-list{
        width: 95%;
    }
  
    .tool-list .swiper-wrapper{
        gap: 35px;
    } 
    .tool-list .swiper{
        margin-left: 25px;
    }
   

    .tool-list .swipe-expand{
        width: 350px !important ;
    }

}
 


@media all and (max-width: 991px) {

    /* .tool-list{
        width: 75%;
        justify-content: space-between;
    } */
    .toollists-head{
        justify-items: center;
    }
    /* .tool-card.tool-expanded{
        width: 50%;
    } */
    .tool-card.tool-expanded h2{
        white-space: break-spaces;
    }
}

@media all and (max-width: 769px) {
    .tool-list{
        position: relative;

    }
    .tool-list .swiper-wrapper{
        gap: 50px;
    } 
    .tool-list .swiper{
        margin-left: 0;
    }
   .tool-list-prev{
    position: absolute;
    bottom: -60px;
    left: 40%;
    margin-right: 10px;
   }
   
   .tool-list-next{
    margin-left: 10px;
    position: absolute;
    bottom: -60px;
    right: 40%;
   }
    
}
@media all and (max-width: 600px) {
   
    .tool-list .swiper-wrapper {
        gap: 45px;
    }
    .tool-list-prev{
        left: 35%;
       }
       
       .tool-list-next{
        right: 35%;
       }

}
@media all and (max-width: 376px) {
.tool-list .swiper-wrapper {
    gap: 40px;
}
.tool-list .swipe-expand{
    width: 320px !important;
}
.tool-list .swiper {
    margin-left: 15px;
}
.nave-active::after {
    left: 43%;
}

}
@media all and (min-width: 1300px) {
   .tool-list .swiper{
    margin-left: 20px;
   }
    .tool-list .swiper-wrapper {
        gap: 40px;
    }
}
@media all and (min-width: 1441px) {
    
    .tool-list .swiper-wrapper {
        gap: 70px;
    }
}


