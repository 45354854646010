.nave {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 25px 10px 30px;
  gap: 5%;
}
.nave .navbar{
  position: unset;
}
.nave-right {
  display: flex;
  width: 84%;
  gap: 50px;
  align-items: center;
  justify-content: flex-end;
}
.nave-phone {
  display: flex;
  align-items: center;
  gap: 10px;
}
.nave-phone p {
  font-family: var(--font-family);
  font-size: .85rem;
  font-weight: 550;
  line-height: 24px;
  text-align: left;
  margin: 0;
}
.nave-search {
  background-color: #000000;
  color: #ffffff;
  width: 22%;
  padding: 5px;
  border-radius: 7px;
}
.nave-search input {
  font-weight: 550;
  color: white;
  background-color: black;
  outline: none;
  height: 25px;
  width: 100%;
  padding: 0;
  border: none;
}
.nave-search input::placeholder {
  color: white;
}

.nave-menu {
  display: flex;
  gap: 25px;
}
.nave-menu a,
.nave-menu p {
  text-decoration: none;
  font-family: var(--font-family);
  font-size: .85rem;
  font-weight: 550;
  line-height: 26px;
  text-align: left;
  color: #000000;
}

.nave-menu p {
  margin: 0;
}
.nave-menu p {
  margin-left: 3px;
}

#product {
  cursor: pointer;
}
.dropdown-toggle::after {
  content: "" !important;
  border: 0 !important;
  vertical-align: 0.15em !important;
}
.dropdown {
  position: unset !important;
  cursor: pointer;
}
.dropdown-menu.show {
  display: flex !important;
}

.dropdown-menu {
  left: 0px !important;
  width: 100% !important;
  top: 114px !important;
  border-radius: 0;
  /* margin: 40px 0 !important; */
  border: none !important;
  padding: 20px 20px 80px !important;
  background-color: #fbfcff !important;
}
.nave-active {
  position: relative;
  text-align: center;
}
.nave-active::after {
  content: "";
  display: inline-block;
  border-radius: 7px;
  margin: 0 auto !important;
  left: 0;
  right: 0;
  width: 40px;
  bottom: -14px;
  height: 2px;
  background: linear-gradient(90deg, #62cff4 0%, #2c67f2 100%);
  position: absolute;
}



.dropdown.show img {
  transform: rotate(180deg) !important;
}


.nave-dropdown-left{
  padding: 0px 20px 0 10px;
  border-right: 1.5px solid;
  width: 20%;
}
.nave-dropdown-center{
  padding: 0px 30px 0 30px;
  border-right: 1.5px solid;
  width: 29%;
}
.nave-dropdown-right{
  width: 55%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.nave-dropdown-right img{
  height: 220px;
  transition: all 2s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.nave-dropdown-right-body{
  display: flex;
    width: 100%;
    gap: 5%;
    justify-content: space-evenly;
}
/* .nave-right-content{
  width: 30%;
} */

.nave-dropdown-left p,.nave-dropdown-center p{
  font-family: var(--font-family);
  font-size: 17px !important;
  font-weight: 500;
  line-height: 36px;
  text-align: left;
  padding-bottom: 10px;
}
.nave-dropdown-left ul,.nave-dropdown-center ul{
     padding: 0;
     list-style: none;
     margin: 0;
}
.nave-dropdown-left ul li,.nave-dropdown-center ul li{
  font-family: var(--font-family);
font-size: 18px !important;
font-weight: 500;
line-height: 36px;
text-align: left;
color: #ADADAD;
padding-bottom: 15px;
}
.nave-dropdown-center .nave-active-img{
  color: #000000;
}
.nave-active-first{
  color: #000000 !important;
}
.nave-read-btn{
  position: absolute !important;
  bottom:17px;
}


.navbar-toggler:focus {
  box-shadow: none !important;
}

.nave-coating{
  width: 117px !important;
  word-wrap: break-word !important;
}
.nave-right-content-head{
  text-align: center !important;
}

.nave-right-content-body {
     font-size: 16px !important;
      font-weight: 400 !important;
      text-align: center !important;
}


@media all and (max-width: 991px) {
  .nave-menu {
    position: absolute;
    width: 94vw;
    left: 30px;
    margin-top: 35px;
    z-index: 2;
    padding: 10px 20px;
    gap: 15px;
    background-color: rgb(251, 252, 255);
    flex-direction: column;
  }
  .dropdown-menu {
    left: -1px !important;
    width: 100.1% !important;
  }
  .nave-menu a,.nave-menu .dropdown-toggle{
    font-size: 1rem;
    text-align: center !important;
  }
  .nave-menu .dropdown-toggle{
    margin-left: 1.5%;
  }
  .dropdown .nave-active::after{
    left: 46.5%;
  }
  
  .nave-active::after {
    right: unset;
    bottom: -7px;
    left: 47.2%;
  }
  .naveProduct-menu{
    width: 95vw !important;
  }
  .nave-dropdown-right{
    justify-content: space-around;
  }
  .nave-dropdown-right img {
    height: 290px;
  }
}


@media all and (max-width: 768px) {
  .nave-menu{
    width: 92vw;
  }
  .nave-menu .navproduct-menu{
    flex-wrap: wrap;
    justify-content: center;
    padding-top: 30px !important;
  }
  .nave-dropdown-left,.nave-dropdown-center{
    width: 50%;
  }
  .nave-dropdown-center{
    padding-left: 40px;
    border-right:0;
  }
  .nave-dropdown-right{
    margin-top: 50px;
    width: 100%;
  }
  .nave-dropdown-right img{
    height: 190px;
  }
}

@media all and (max-width: 670px) {
  .nave{
  margin: 10px 30px 10px 30px;
  gap: 10%;
  }
  .nave-menu{
    width: 90vw;
  }
 .nav-logo img{
  width: 70px;
  height: 70px;
 }
 .nave-right{
  gap: 20px;
 }
 .nave-phone p{
  font-size: .7rem;
 }
 .nave-dropdown-left{
  border-bottom: 1px solid #ADADAD;
  width: 100%;
  border-right: none;
 }
 .nave-dropdown-center{
  width: 100%;
  padding-left: 20px;
  margin-top: 20px;
 }
 .nave-active::after{
  left: 45.5%;
 }
}
@media all and (max-width: 510px) {
  .nave-right .nave-phone{
    display: none;
   }
   .nave-menu{
    width: 87vw;
  }
  .nave-active::after{
    left: 45%;
   }
}
@media all and (max-width: 450px) {
  .nave{
    margin: 10px 20px 10px 30px ;
  }
  .nave-dropdown-right-body{
    width: 100%;

  }
  .nave-right-content p{
    margin-left: 0 !important;
    width: 63px !important;
  }
}

@media all and (min-width: 1300px) {
 
  .nave-phone p,
  .nave-menu a,
  .nave-menu p {
    font-size: 1rem ;
  }
}
/* @media all and (min-width: 1440px) {
 
  .nave-phone p,
  .nave-menu a,
  .nave-menu p {
    font-size: 1.1rem ;
  }
} */



